@tailwind base;
@tailwind components;
@tailwind utilities;

.apexcharts-legend {
  background: #f5f6f9;
  border-radius: 8px;
  height: fit-content;
  align-items: start;
  max-height: 200px;
}

.apexcharts-legend-series {
  line-height: 100% !important;
}

div.leaflet-overlay-pane svg {
  pointer-events: initial !important;
  width: 100%;
  height: 100%;
  transform: translate3d(0, 0, 0) !important;
}

.leaflet-interactive {
  cursor: pointer;
}

.apexcharts-tooltip {
  white-space: pre-wrap !important;
  max-width: 300px;
}

.apexcharts-tooltip-marker {
  min-width: 12px !important;
}

.apexcharts-legend-series {
  text-align: left !important;
}
